import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { options } from '@utils/options';

interface Props {
  data: string[];
  handleClick: (id: number) => void;
}

export default function Answers({ data, handleClick }: Props) {
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();

  return (
    <div className="bg-platzi-dark-second px-4 py-5">
      <p className="mt-1 mb-4 max-w-2xl text-sm text-platzi-white">{t('answers.instruction')}</p>
      <RadioGroup value={selected} onChange={setSelected}>
        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
        <div className="space-y-2">
          {data?.map((answer, index) => (
            <RadioGroup.Option
              key={`answer-${index}`}
              value={index}
              onClick={() => handleClick(index)}
              role="button"
              className={({ active, checked }) =>
                `${
                  active
                    ? 'ring-2 ring-offset-2 ring-offset-sky-300 ring-white ring-opacity-60'
                    : ''
                }
                  ${checked ? 'bg-sky-900 bg-opacity-75 text-white' : 'bg-platzi-dark-third'}
                    relative rounded-lg shadow-md px-5 py-4 cursor-pointer flex focus:outline-none`
              }
            >
              {({ checked }) => (
                <>
                  <div
                    className="flex items-center justify-between w-full"
                    aria-hidden="true"
                    role="button"
                    onClick={() => handleClick(index)}
                  >
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className={`font-medium strong text-platzi-white2`}
                        >
                          {options[index]}
                        </RadioGroup.Label>
                        <RadioGroup.Description as="span" className={`inline text-platzi-white`}>
                          <span>{answer}</span>
                        </RadioGroup.Description>
                      </div>
                    </div>
                    {checked && (
                      <div className="flex-shrink-0 text-white">
                        <CheckIcon className="w-6 h-6" />
                      </div>
                    )}
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
}
